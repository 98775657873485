import { ServiceLayout } from '../../../components/ServicesLayout';
import { servicesConfig } from './serviceConfig';

export const UxDesign = () => {
  const uxDesignConfig = servicesConfig.uxDesign;

  return (
    <section>
      <ServiceLayout
        title={uxDesignConfig.title}
        paragraphPrimary={uxDesignConfig.paragraphPrimary}
        cardItems={uxDesignConfig.cardItems}
        steps={uxDesignConfig.steps}
        mainImage={uxDesignConfig.mainImage}
        gradientImage={uxDesignConfig.gradientImage}
        arrowIcon={uxDesignConfig.arrowIcon}
      />
    </section>
  );
};
