import Banner from '../../assets/resell/banner.png';
import { HeaderMobile } from '../../components/HeaderMobile';
import { HeaderComputer } from '../../components/HeaderComputer';
import Coins from '../../assets/resell/coins.png';
import { getResell } from '../../service/Blockchain';
import { useState } from 'react';
import { Footer } from '../../components/Footer';
export const Resell = () => {

    const [code, setCode] = useState<any>();
    const [valid, setValid] = useState();
    const [checkout, setCheckout] = useState(0);

    async function isValid() {
        if (!code || typeof code !== "string") {
            return;
        }
        const result: boolean | any = await getResell(code);
        setValid(result);
        setCheckout(1);
    }
    

    return (
        <div className='h-auto overflow-hidden'>
        
            <div className="lg:hidden">
            <HeaderMobile/>
            </div>
            
            <div className="hidden lg:block">
                <HeaderComputer />
            </div>

            <img className='md:w-screen md:h-[700px]' src={Banner} alt='banner'/>
            
            <article className='w-full'>
                <ul className='mt-10 p-2 md:flex flex-col justify-center items-center'>
                    <li className='text-slate-400 font-bold md:text-2xl'>Por Que Ser um Revendedor da Next Chain?</li>
                    <p className='text-sm text-slate-500 md:w-6/12 md:text-lg md:text-center pb-2'>
                        Tornar-se um revendedor da Next Chain é uma excelente
                        forma de entrar no mercado de blockchain com uma estrutura
                        de apoio sólida. Oferecemos produtos de alta qualidade que
                        refletem o melhor da tecnologia Web3, além de comissões
                        competitivas, superando o que o mercado tradicional oferece.
                        A Next Chain também entende que flexibilidade é essencial,
                        então você poderá escolher receber suas comissões em
                        criptomoedas como BTC, ETH ou USDT, ou mesmo em
                        dólares. E, com suporte dedicado, você terá acesso a
                        ferramentas e treinamentos para maximizar suas vendas.
                        Transforme seu potencial em lucro!
                    </p>
                    <img className='lg:w-6/12 md:rounded-xl w-screen' src={Coins} alt='coins'/>
                </ul>
                <div className='h-96 flex flex-col items-center mt-1'>
                    <label className='font-bold text-slate-500'>Coloque o código do vendedor</label>
                    <input onChange={(e:any) => setCode(e.target.value)} className='w-10/12 border-2 rounded-md p-1 '/>
                    <button onClick={isValid} className='p-1 bg-purple-primary w-6/12 mt-2 rounded-md text-white font-semibold'>Verificar</button>

                    {checkout === 1 &&
                    <div className='flex justify-center items-center'>
                        {valid ?
                            <div>
                                <div className='flex flex-col justify-center items-center mt-5'>
                                <p className='font-bold'>Este vendedor existe!</p>
                                <p className='text-center'>Em caso de desconfiança entre em contato no email - analystic@nxchain.link</p>
                            </div>
                        </div>
                        :
                        <div className='flex flex-col justify-center items-center mt-5'>
                            <p className='font-bold'>Este vendedor não existe!</p>
                            <p className='text-center'>Por favor, informe os dados e print no email - analystic@nxchain.link</p>
                        </div>
                        }
                    </div>
                }
                
                </div>

            </article>
            <Footer/>
        </div>
    )
}