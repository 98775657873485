import SMCONTRACT from '../../../assets/services/smcontract.png';
import IconAudit from '../../../assets/icon/smcontract.png';
import Shield from '../../../assets/icon/escudo.png';
import Process from '../../../assets/icon/nos.png';
import AuditGradient from '../../../assets/services/smc-gradient.png';
import Arrow from '../../../assets/icon/arrow.png';
export const servicesConfig = {
    // Outros serviços...
    dappsDevelopment: {
      title: 'DApps DEVELOPMENT',
      paragraphPrimary:
        'Build Decentralized Applications with Cutting-Edge Blockchain Technology to Empower Users and Innovate the Market.',
      cardItems: [
        {
          title: 'Custom Solutions',
          paragraph: `Tailored DApps for your business needs! From DeFi platforms to NFT marketplaces, we design and develop decentralized applications that meet your unique goals.`,
          icon: IconAudit, // Reutilizando ícone
        },
        {
          title: 'Security and Transparency',
          paragraph: `Ensure trust with robust security measures and complete transparency. Our DApps are built with auditable and immutable blockchain technology to protect users and assets.`,
          icon: Shield, // Reutilizando ícone
        },
        {
          title: 'Scalability and Efficiency',
          paragraph: `Optimize your application for performance. Our DApps are designed to scale as your user base grows, ensuring smooth operation and an excellent user experience.`,
          icon: Process, // Reutilizando ícone
        },
      ],
      steps: [
        {
          title: 'REQUIREMENTS GATHERING',
          paragraph: 'Understand the business needs and user requirements to design the perfect decentralized solution.',
        },
        {
          title: 'TECH STACK SELECTION',
          paragraph: 'Choose the best blockchain, tools, and frameworks for your project’s specific needs.',
        },
        {
          title: 'ARCHITECTURE DESIGN',
          paragraph: 'Plan the DApp architecture, including smart contracts, APIs, and user interfaces.',
        },
        {
          title: 'DEVELOPMENT & TESTING',
          paragraph: 'Develop the application following best practices and perform rigorous testing to ensure security and functionality.',
        },
        {
          title: 'DEPLOYMENT & SUPPORT',
          paragraph: 'Launch the DApp on the chosen blockchain network and provide ongoing maintenance and updates.',
        },
      ],
      mainImage: SMCONTRACT, // Reutilizando imagem
      gradientImage: AuditGradient, // Reutilizando imagem
      arrowIcon: Arrow, // Reutilizando imagem
    },
  };
  