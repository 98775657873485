import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Home,
  SecurityAudit,
  Portfolio,
  MintPage,
  Resell,
  SmartContract,
  UxDesign,
  Dapps,
  Back,
  Mobile3
} from "../containers";
import { About } from "../containers/Company/About";
import { LoadingPage } from "../containers/LoadingPage";

export const Routers = (): JSX.Element => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/loading" element={<LoadingPage/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/portfolio" element={<Portfolio/>}/>
        <Route path="/mint" element={<MintPage/>}/>
        <Route path="/resell" element={<Resell/>}/>
        <Route path="/smartcontract" element={<SmartContract/>}/>
        <Route path="/design" element={<UxDesign/>}/>
        <Route path="/security" element={<SecurityAudit/>} />
        <Route path="/dapps" element={<Dapps/>} />
        <Route path="/backend" element={<Back/>} />
        <Route path="/mobile" element={<Mobile3/>} />
      </Routes>
    </Router>
  );
};
