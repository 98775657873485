import { useState, useEffect } from 'react';
import Logo from '../../assets/img/logo-redonda.svg';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';

export const HeaderComputer = (): JSX.Element => {
    const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>({
        services: false,
        company: false,
        contact: false,
    });

    const {i18n, t}= useTranslation()

    const [act, setAct] = useState(false);

    const [scrollY, setScrollY] = useState<number>(0);

    const handleScroll = (): void => {
        setScrollY(window.scrollY);
    };

    const handleAct = () => setAct(!act);

    const handleTranslatePT = () => i18n.changeLanguage('pt');
    const handleTranslateEN = () => i18n.changeLanguage('en');

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMouseEnter = (dropdown: string) => {
        setDropdownOpen((prevState) => ({ ...prevState, [dropdown]: true }));
    };

    const handleMouseLeave = (dropdown: string) => {
        setDropdownOpen((prevState) => ({ ...prevState, [dropdown]: false }));
    };

    return (
        <div className={`flex items-center justify-around p-5 ${scrollY < 100 ? '' : 'fixed top-0 left-0 w-screen bg-black z-50'}`}>
            <a href="/">
                <img className={`w-10 ${scrollY < 100 ? '' : 'border rounded-md'}`} src={Logo} alt='icon' />
            </a>

            <div className='flex items-center gap-20'>
                <a 
                    href="https://hub.nxchain.link/" 
                    className='bg-purple-primary p-1 py-2 rounded-md text-white font-bold'>
                    {t('header.request')}
                </a>

                <div className='flex gap-14 text-secundary-blue font-bold'>
                    <a className={`${scrollY < 100 ? 'text-black' : 'text-white'}`} href='https://blog.nxchain.link/' rel="noreferrer" target='_blank'>Blog</a>
                    <div
                        onMouseEnter={() => handleMouseEnter('services')}
                        onMouseLeave={() => handleMouseLeave('services')}
                        className='relative'
                    >
                        {/* <button className={`hover:scale-110 hover:opacity-70 ${scrollY < 100 ? 'text-black' : 'text-white'}`}>
                            Serviços
                        </button>
                        {dropdownOpen.services && (
                            <div
                                className='absolute mt-1 flex flex-col bg-white w-96 gap-5 p-2 shadow-lg rounded-md'
                                onMouseEnter={() => handleMouseEnter('services')}
                                onMouseLeave={() => handleMouseLeave('services')}
                            >
                                <a className='font-semibold' href="/defi">DeFi</a>
                                <a className='font-semibold' href="/security">Auditoria de Segurança</a>
                                <a className='font-semibold' href='/tokens'>Soluções de Tokens</a>
                                <a className='font-semibold' href='/nfts'>Soluções NFT's</a>
                            </div>
                        )} */}
                    </div>

                    <div
                        onMouseEnter={() => handleMouseEnter('company')}
                        onMouseLeave={() => handleMouseLeave('company')}
                        className='relative'
                    >
                        <button 
                        className={`hover:scale-110 hover:opacity-70 ${scrollY < 100 ? 'text-black' : 'text-white'}`}>
                            {t('header.links.company')}
                        </button>

                        {dropdownOpen.company && (
                            <div
                                className='absolute mt-1 flex flex-col bg-white w-80 gap-5 p-2 shadow-lg rounded-md'
                                onMouseEnter={() => handleMouseEnter('company')}
                                onMouseLeave={() => handleMouseLeave('company')}
                            >
                                <a className='font-semibold' href="/about">{t('header.company')}</a>
                                <a className='font-semibold' href="/portfolio">Portfolio</a>
                                <a className='font-semibold' href="https://hub.nxchain.link/">NXHUB</a>
                            </div>
                        )}
                    </div>

                    <div
                        onMouseEnter={() => handleMouseEnter('contact')}
                        onMouseLeave={() => handleMouseLeave('contact')}
                        className='relative'
                    >
                        <button className={`hover:scale-110 hover:opacity-70 ${scrollY < 100 ? 'text-black' : 'text-white'}`}>
                        {t('header.links.contact')}
                        </button>
                        {dropdownOpen.contact && (
                            <div
                                className='absolute mt-1 flex flex-col bg-white w-32 h-72 gap-5 p-2 shadow-lg rounded-md'
                                onMouseEnter={() => handleMouseEnter('contact')}
                                onMouseLeave={() => handleMouseLeave('contact')}
                            >
                                <a className='font-semibold' href="https://web.telegram.org/k/#@nxchainofc">Telegram: @nxchainofc</a>
                                <a className='font-semibold' href="mailto:nextchain@nxchain.link">E-mail</a>
                                <a className='font-semibold' href="https://api.whatsapp.com/send?phone=5513974221906&text=Ol%C3%A1,%20fui%20redirecionado%20via%20(website)%20e%20gostaria%20de%20saber%20mais%20sobre%20seus%20produtos%20e%20servi%C3%A7os.%20%F0%9F%98%83%0A%0A">WhatsApp</a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <button onClick={handleAct}><LanguageIcon/></button>
            {
                act&&
                <div className='absolute right-20 flex flex-col'>
                    <button onClick={handleTranslatePT} className='font-bold hover:opacity-40'>PT-BR</button>
                    <button onClick={handleTranslateEN} className='font-bold hover:opacity-40'>EN-US</button>
                </div>
            }
        </div>
    );
};
