import SMCONTRACT from '../../../assets/services/smcontract.png';
import IconAudit from '../../../assets/icon/smcontract.png';
import Shield from '../../../assets/icon/escudo.png';
import Process from '../../../assets/icon/nos.png';
import AuditGradient from '../../../assets/services/smc-gradient.png';
import Arrow from '../../../assets/icon/arrow.png';
export const servicesConfig = {
    // Outros serviços...
  
    mobileWeb3: {
      title: 'iOS/ANDROID WEB3 DEVELOPMENT',
      paragraphPrimary:
        'Build Seamless and Secure Mobile Applications with Blockchain Integration for iOS and Android Devices.',
      cardItems: [
        {
          title: 'Blockchain Integration for Mobile',
          paragraph: `Connect your mobile app to blockchain networks effortlessly. We implement Web3 technologies to enable secure transactions, wallet connections, and smart contract interactions directly from mobile devices.`,
          icon: IconAudit,
        },
        {
          title: 'Cross-Platform Development',
          paragraph: `Develop once, deploy everywhere. We create cross-platform mobile applications for both iOS and Android, ensuring optimal user experiences and blockchain interactions on all devices.`,
          icon: Shield,
        },
        {
          title: 'User-Friendly Wallets and Transactions',
          paragraph: `Build intuitive, secure mobile wallets and streamline transaction processes, allowing users to easily store and transfer assets while interacting with decentralized applications (dApps).`,
          icon: Process,
        },
      ],
      steps: [
        { title: 'IDEALIZATION AND SCOPING', paragraph: 'Understand your app’s functionality, define user requirements, and determine the blockchain integration needed.' },
        { title: 'MOBILE FRAMEWORK SELECTION', paragraph: 'Choose the best framework for your mobile application, such as React Native or Flutter, ensuring cross-platform compatibility.' },
        { title: 'BLOCKCHAIN CONNECTIVITY', paragraph: 'Integrate with blockchain networks using libraries such as Web3.js, ethers.js, and WalletConnect for secure mobile interactions.' },
        { title: 'MOBILE UI/UX DESIGN', paragraph: 'Create engaging and intuitive mobile interfaces, ensuring a seamless user experience with Web3 technologies.' },
        { title: 'DEVELOPMENT, TESTING, AND DEPLOYMENT', paragraph: 'Develop the app, test it on various devices, fix bugs, and deploy the mobile app to App Store and Google Play.' },
      ],
      mainImage: SMCONTRACT,
      gradientImage: AuditGradient,
      arrowIcon: Arrow,
    },
  };
  