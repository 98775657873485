import SMCONTRACT from '../../../assets/services/smcontract.png';
import IconAudit from '../../../assets/icon/smcontract.png';
import Shield from '../../../assets/icon/escudo.png';
import Process from '../../../assets/icon/nos.png';
import AuditGradient from '../../../assets/services/smc-gradient.png';
import Arrow from '../../../assets/icon/arrow.png';

export const servicesConfig = {
  smartContract: {
    title: 'SMART CONTRACT',
    paragraphPrimary:
      'Build Secure and Efficient Smart Contracts with a Blockchain Expert Company',
    cardItems: [
      {
        title: 'Problem Solved and Efficiency',
        paragraph: `Streamline Complex Processes! A smart contract eliminates intermediaries, cuts costs, and speeds up transactions. With automation, it ensures precise and reliable executions, saving time and resources. Perfect for payments, agreements, staking, and asset tokenization!`,
        icon: IconAudit,
      },
      {
        title: 'Security and Reliability',
        paragraph: `Trust in Blockchain Technology! Smart contracts are immutable and auditable, protecting against manipulation and fraud. They provide complete transparency, boosting user trust and ensuring that the contract's rules are executed without exceptions.`,
        icon: Shield,
      },
      {
        title: 'Decentralization and Control',
        paragraph: `Empower the User! Operating on decentralized networks, smart contracts remove reliance on third parties. Control lies with the users, fostering a fairer, more transparent economy with no global barriers.`,
        icon: Process,
      },
    ],
    steps: [
      { title: 'PHASE 1', paragraph: 'You will go through a rigorous support phase with our experts to understand your solution and how we can help you.' },
      { title: 'PHASE 2', paragraph: 'In phase 2, after your idea has been validated, we will assess your understanding of Web3 and its current level, enabling you to use, comprehend, and further enhance your own solution.' },
      { title: 'PHASE 3', paragraph: 'Choosing the perfect network, assessing the need for infrastructure, smart contract architecture, control flows, and identifying opportunities for improvement.' },
      { title: 'PHASE 4', paragraph: 'We begin the project development using the best development standards. Afterward, the project undergoes a rigorous testing phase, where we identify and resolve any errors, poorly written code, or non-functional components.' },
      { title: 'PHASE 5', paragraph: 'After our fully efficient development journey and the client’s 100% approval of all functionalities, we document the smart contract and proceed with its deployment.' },
    ],
    mainImage: SMCONTRACT,
    gradientImage: AuditGradient,
    arrowIcon: Arrow,
  },
  securityAudit: {
    title: 'SECURITY AUDIT',
    paragraphPrimary:
      'Ensure the Security and Reliability of Your Blockchain Applications with a Professional Security Audit.',
    cardItems: [
      {
        title: 'Identify Vulnerabilities',
        paragraph: `Through a detailed analysis, we identify vulnerabilities in your smart contracts, blockchain applications, or infrastructure to prevent attacks and exploits.`,
        icon: IconAudit,
      },
      {
        title: 'Strengthen Security',
        paragraph: `We provide actionable insights and solutions to address identified issues, ensuring your project meets the highest security standards.`,
        icon: Shield,
      },
      {
        title: 'Gain Community Trust',
        paragraph: `An audit boosts confidence among users and investors, showcasing a commitment to safety, transparency, and professional development.`,
        icon: Process,
      },
    ],
    steps: [
      { title: 'INITIAL CONSULTATION', paragraph: 'Understand the scope of your project, define the goals of the audit, and identify key components for evaluation.' },
      { title: 'AUTOMATED TESTING', paragraph: 'Run automated tools to scan for common vulnerabilities and ensure adherence to development standards.' },
      { title: 'MANUAL ANALYSIS', paragraph: 'Perform a detailed manual review of the codebase to uncover less obvious vulnerabilities and logic errors.' },
      { title: 'REPORT DELIVERY', paragraph: 'Provide a comprehensive report outlining vulnerabilities, recommended solutions, and a security score.' },
      { title: 'PATCH REVIEW', paragraph: 'Evaluate fixes applied to address identified issues, ensuring all vulnerabilities have been effectively resolved.' },
    ],
    mainImage: SMCONTRACT, // Adicione uma imagem personalizada, se necessário.
    gradientImage: AuditGradient,
    arrowIcon: Arrow,
  },
};