import { ServiceLayout } from '../../../components/ServicesLayout';
import { servicesConfig } from './serviceConfig';

export const Back = () => {
  const backendConfig = servicesConfig.web3Backend;

  return (
    <section>
      <ServiceLayout
        title={backendConfig.title}
        paragraphPrimary={backendConfig.paragraphPrimary}
        cardItems={backendConfig.cardItems}
        steps={backendConfig.steps}
        mainImage={backendConfig.mainImage}
        gradientImage={backendConfig.gradientImage}
        arrowIcon={backendConfig.arrowIcon}
      />
    </section>
  );
};
