import Logo from '../../assets/img/logo-redonda.svg';
import { Footer } from '../Footer';

export const ServiceLayout = ({
  title,
  paragraphPrimary,
  cardItems,
  steps,
  mainImage,
  gradientImage,
  arrowIcon,
}: {
  title: string;
  paragraphPrimary: string;
  cardItems: Array<{ title: string; paragraph: string; icon: string }>;
  steps: Array<{ title: string; paragraph: string }>;
  mainImage: string;
  gradientImage: string;
  arrowIcon: string;
}) => {
  return (
    <section>
      {/* Header Section */}
      <article className="bg-service-primary bg-cover flex justify-between pb-56">
        <div>
          <div className="flex items-center gap-2 p-2">
            <img className="w-12" src={Logo} alt="logo-nextchain" />
            <h4 className="font-bold">{title}</h4>
          </div>
          <div className="flex flex-col gap-2 mt-10 p-2 md:w-6/12 md:gap-10">
            <h5 className="text-xl text-white font-bold md:text-3xl">{paragraphPrimary}</h5>
            <button className="bg-purple-primary text-white p-2 rounded-lg w-6/12 font-semibold">
              Request a Quote
            </button>
          </div>
        </div>
        <div>
          <img className="w-96 z-20" src={mainImage} alt="smart-contract-development" />
        </div>
      </article>

      {/* Card Section */}
      <article className="md:mt-20">
        <div>
          <hr className="bg-purple-primary w-32 h-1 ml-2" />
          <div className="flex flex-col gap-2">
            <h6 className="text-purple-primary text-xl font-bold p-2 md:text-4xl">Turn Your Ideas into Blockchain Solutions!</h6>
            <p className="p-2 text-purple-primary md:w-6/12 md:text-2xl">
              With years of experience in Web3 development, We help businesses and entrepreneurs create custom, secure, and optimized smart contracts tailored to meet the most demanding market needs.
            </p>
          </div>
        </div>
      </article>

      <article>
        <div className="md:flex">
          {cardItems.map((i, index) => (
            <div className="flex flex-col justify-center items-center" key={index}>
              <img src={i.icon} alt="icon-service-page-nextchain" />
              <h4 className="text-purple-primary font-bold">{i.title.toUpperCase()}</h4>
              <p className="text-center text-slate-500">{i.paragraph}</p>
            </div>
          ))}
        </div>
      </article>

      <article className='mt-20'>
         <div className='flex flex-col justify-center items-center'>
            <hr className='bg-purple-primary h-1 w-3/12'></hr>
            <h5 className='text-purple-primary text-2xl font-bold mt-2'>View all phases</h5>
         </div>
      </article>
      <article className="h-auto bg-cover mt-10">
        <div className="p-2 flex flex-col justify-center items-center">
          {steps.map((i, index) => (
            <div className="flex flex-col items-center mt-10" key={index}>
              <h6 className="text-purple-primary font-bold text-xl md:text-2xl">{i.title}</h6>
              <p className="text-center w-10/12 text-slate-500 md:w-3/12 text-xl">{i.paragraph}</p>
              {index < steps.length - 1 && (
                <div className="flex flex-col justify-center items-center">
                  <img src={arrowIcon} alt="arrow-icon" />
                </div>
              )}
            </div>
          ))}
          <a href="https://hub.nxchain.link/orcamento" className="mt-10 bg-purple-primary w-3/12 flex justify-center items-center p-2 rounded-lg text-white font-bold">
            Start now!
          </a>
        </div>
      </article>
      <Footer />
    </section>
  );
};
