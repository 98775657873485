import { ServiceLayout } from '../../../components/ServicesLayout';
import { servicesConfig } from './serviceConfig';

export const Mobile3 = () => {
  const mobileConfig = servicesConfig.mobileWeb3;

  return (
    <section>
      <ServiceLayout
        title={mobileConfig.title}
        paragraphPrimary={mobileConfig.paragraphPrimary}
        cardItems={mobileConfig.cardItems}
        steps={mobileConfig.steps}
        mainImage={mobileConfig.mainImage}
        gradientImage={mobileConfig.gradientImage}
        arrowIcon={mobileConfig.arrowIcon}
      />
    </section>
  );
};
