import { ServiceLayout } from '../../../components/ServicesLayout';
import { servicesConfig } from './ServiceConfig';

export const SecurityAudit = () => {
  const securityAuditConfig = servicesConfig.securityAudit;

  return (
    <section>
      <ServiceLayout
        title={securityAuditConfig.title}
        paragraphPrimary={securityAuditConfig.paragraphPrimary}
        cardItems={securityAuditConfig.cardItems}
        steps={securityAuditConfig.steps}
        mainImage={securityAuditConfig.mainImage}
        gradientImage={securityAuditConfig.gradientImage}
        arrowIcon={securityAuditConfig.arrowIcon}
      />
    </section>
  );
};
