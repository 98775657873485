import { ServiceLayout } from '../../../components/ServicesLayout';
import { servicesConfig } from './serviceConfig';

export const SmartContract = () => {
  const smartContractConfig = servicesConfig.smartContract;

  return (
    <section>
      <ServiceLayout
        title={smartContractConfig.title}
        paragraphPrimary={smartContractConfig.paragraphPrimary}
        cardItems={smartContractConfig.cardItems}
        steps={smartContractConfig.steps}
        mainImage={smartContractConfig.mainImage}
        gradientImage={smartContractConfig.gradientImage}
        arrowIcon={smartContractConfig.arrowIcon}
      />
    </section>
  );
};
