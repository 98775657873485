import SMCONTRACT from '../../../assets/services/smcontract.png';
import IconAudit from '../../../assets/icon/smcontract.png';
import Shield from '../../../assets/icon/escudo.png';
import Process from '../../../assets/icon/nos.png';
import AuditGradient from '../../../assets/services/smc-gradient.png';
import Arrow from '../../../assets/icon/arrow.png';

export const servicesConfig = {
  
    web3Backend: {
      title: 'WEB3 BACK-END',
      paragraphPrimary:
        'Develop Robust Back-End Solutions for Decentralized Applications, Ensuring Seamless Connectivity with Blockchain Networks.',
      cardItems: [
        {
          title: 'Smart Contract Integration',
          paragraph: `Connect seamlessly with blockchain networks. Our back-end solutions ensure smooth interaction between your DApp and smart contracts, delivering real-time data and reliable functionality.`,
          icon: IconAudit,
        },
        {
          title: 'Scalability and Performance',
          paragraph: `Handle high user demands with ease. We design back-ends that are optimized for performance, scalability, and fault tolerance, ensuring a great user experience even during traffic spikes.`,
          icon: Shield,
        },
        {
          title: 'Custom APIs for Blockchain',
          paragraph: `Simplify blockchain interactions. We build custom APIs tailored to your business logic, enabling efficient data retrieval and transactions for your Web3 applications.`,
          icon: Process,
        },
      ],
      steps: [
        { title: 'REQUIREMENTS ANALYSIS', paragraph: 'Understand your DApp needs and define the architecture and data flow for the back-end system.' },
        { title: 'BLOCKCHAIN CONNECTIVITY', paragraph: 'Integrate with blockchain networks using libraries like Web3.js, ethers.js, or custom solutions.' },
        { title: 'API DEVELOPMENT', paragraph: 'Build RESTful or GraphQL APIs to enable secure and efficient interaction with blockchain data.' },
        { title: 'TESTING & SECURITY', paragraph: 'Perform rigorous testing to ensure API reliability and secure communication with blockchain nodes.' },
        { title: 'DEPLOYMENT & MONITORING', paragraph: 'Deploy back-end services to cloud infrastructure and implement monitoring tools for seamless performance.' },
      ],
      mainImage: SMCONTRACT,
      gradientImage: AuditGradient,
      arrowIcon: Arrow,
    },
  };
  