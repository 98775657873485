import { HeaderMobile } from "../../components/HeaderMobile";
import { HeaderComputer } from "../../components/HeaderComputer";
import CallMadeIcon from "@mui/icons-material/CallMade";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Benefits } from "../../pages/Benefits";
import { BannerWhitepaper } from "../../pages/BannerWhitepaper";
import { Products } from "../../pages/Products";
import { Ressel } from "../../pages/Ressel";
import { Certic } from "../../pages/Certic";
import HomeImage from "../../assets/img/home-image.png";
import { Footer } from "../../components/Footer";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NetWorks from '../../assets/icon/networks.png';
import WalletLogos from '../../assets/icon/wallet.png';
import { ContactArea } from "../../components/ContactArea";
import TerminalIcon from '@mui/icons-material/Terminal';
import GppGoodIcon from '@mui/icons-material/GppGood';
import BugReportIcon from '@mui/icons-material/BugReport';
import BrushIcon from '@mui/icons-material/Brush';
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


export const Home = (): JSX.Element => {

  const [state, setState] = useState(false);
  const [newClient, setClient] = useState(0);
  const [newDesign, setDesign] = useState(0);
  const [newFindings, setFidings] = useState(0);
  const [newSmart, setSmarts] = useState(0);
  const { t } = useTranslation();



  function handleOpenContactArea(){
      setState(!state);
  }

  useEffect(() => {
    
      const clients = 16;
      const design = 52;
      const fidings = 23;
      const smart = 32;

      const countUp = (setter: (value:number) => void, target:number, duration:number) => {
        let start = 0;
        const step = Math.ceil(target / (duration / 50));
        const timer = setInterval(() => {
          if (start < target) {
            start += step;
            if (start > target) start = target;
            setter(start);
          } else {
            clearInterval(timer);
          }
        }, 50);
      };

      countUp(setClient, clients, 5000);
      countUp(setDesign, design, 5000);
      countUp(setFidings, fidings, 5000);
      countUp(setSmarts, smart, 5000);

  },[]);

  return (
    <div className="bg-white h-auto w-full overflow-x-hidden">
      <div className="lg:hidden">
          <HeaderMobile/>
      </div>
      
      <div className="hidden lg:block">
        <HeaderComputer />
      </div>

      <div className="lg:flex lg:flex-row-reverse lg:items-center lg:mt-32 lg:pb-20 flex flex-col items-center lg:ml-[5%]">
        <img className="lg:w-[800px]" src={HomeImage} alt="icon" />

        <div className="pl-3 mt-5 flex flex-col gap-2 pb-20 lg:ml-32">
          <h1
            className="text-2xl w-[250px] font-bold text-blue-secundary
            lg:text-6xl lg:w-[500px]
            "
          >
            {t('home.title')}
            <span
              className="text-xl
              lg:text-4xl
              "
            >
              {" "}
              {t("home.title2")}
            </span>
          </h1>

          <h2
            className="text-sm text-blue-secundary max-w-96
            lg:text-2xl lg:max-w-[600px]
            "
          >
            {t("home.paragraph")}
          </h2>

          <div className="flex items-center gap-5 mt-5">
            <a href="https://pdf-nextchain.s3.us-east-1.amazonaws.com/Next+Chain+Blockchain+Solutions+LTDA.pdf" className="bg-purple-primary p-1 rounded-md text-white"  >Whitepaper <CallMadeIcon /></a>
            <a href="https://hub.nxchain.link" 
            className="text-blue-secundary font-semibold">
              NextChain HUB<KeyboardArrowRightIcon />
            </a>
          </div>
        </div>
      </div>

      <div>
         <div className="p-5 flex  flex-col justify-center items-center">
             <div className="md:grid grid-cols-2 justify-center gap-10">
              <article className="gap-10 md:border md:p-5 md:rounded-2xl md:border-slate-400">

                  <label className="font-bold">
                    <span className="border-b-[3px] border-purple-primary mr-1">
                      NxChain 
                    </span>
                    Security Services
                  </label>

                  <section className="lg:flex gap-10">
                      <div className="flex gap-10 p-2 mt-5 border-2 border-slate-400 rounded-xl md:border-0">
                        <div className="flex flex-col gap-2  p-2  
                            md:border-0 md:border-r-2 md:rounded-none md:pr-4">
                          <h6 className="font-semibold text-slate-500">Smart Contract Audit</h6>
                          <img className="w-40" src="https://img.freepik.com/vetores-premium/conjunto-de-logotipos-de-criptomoedas-bitcoin-cardano-ethereum-shiba-inu-logotipos-de-criptografia-litecoinvetor_689336-952.jpg?semt=ais_hybrid" alt="cryptocurrency-logos"/>
                        </div>
                      </div>

                      <div className="flex justify-center flex-col mt-5">
                        <div className="flex flex-col border-2 gap-2 border-slate-400 rounded-xl p-2
                        md:border-0 md:border-r-2 md:rounded-none md:pr-4
                        ">
                          <h6 className="font-semibold text-slate-500">Blockchain L1/L2 Audit</h6>
                          <img className="w-28" src={NetWorks} alt="cryptocurrency-logos"/>
                        </div>
                      </div>

                      <div className="flex justify-center flex-col mt-5">
                        <div className="flex flex-col border-2 border-slate-400 gap-2 rounded-xl p-2 md:border-none">
                          <h6 className="font-semibold text-slate-500">Wallet Audit</h6>
                          <img className="w-28" src={WalletLogos} alt="cryptocurrency-logos"/>
                        </div>
                      </div>
                  </section>
              </article>
              
      
              <article className="grid grid-cols-2 mt-5 gap-5 font-bold">
                  
                  <div className="border flex justify-center items-center h-auto gap-2 border-slate-400 p-6 rounded-xl">
                      <TerminalIcon className="
                      text-purple-primary"/>
                      <h6>Smart Development</h6>
                  </div>
                  <div className="border border-slate-400 p-6 h-auto rounded-xl gap-2 flex justify-center items-center">
                      <GppGoodIcon className="
                        text-purple-primary
                      "/>
                      <h6>Security Audit</h6>
                  </div>
                  <div className="border border-slate-400 p-6 h-auto rounded-xl gap-2 flex justify-center items-center">
                      <BugReportIcon className="text-purple-primary"/>
                      <h6>PenTesting</h6>
                  </div>
                  <div className="border border-slate-400 p-6 h-auto rounded-xl gap-2 flex justify-center items-center">
                      <BrushIcon className="text-purple-primary"/>
                      <h6>Design UX/UI</h6>
                  </div>
                  <div className="border border-slate-400 p-6 rounded-xl h-auto flex justify-center items-center">
                      <div className="flex flex-col  items-center">
                        <h6>KYC</h6>
                        <p className="text-white bg-purple-primary text-center rounded animate-pulse p-1">Em Breve</p>
                      </div>
                  </div>
                  <div className="border border-slate-400 p-6 h-auto rounded-xl flex justify-center items-center">
                      <div className="flex flex-col items-center">
                        <h6>Bug Bounty</h6>
                        <p className="text-white bg-purple-primary text-center rounded animate-pulse p-1">Em Breve</p>
                      </div>
                  </div>
                  <div className="border border-slate-400 p-6 h-auto rounded-xl flex justify-center items-center">
                      <div className="flex flex-col items-center">
                        <h6>Mentorship</h6>
                        <p className="text-white bg-purple-primary text-center rounded animate-pulse p-1">Em Breve</p>
                      </div>
                  </div>
                  <div className="border border-slate-400 p-6 h-auto rounded-xl flex justify-center items-center">
                      <div className="flex flex-col items-center">
                        <h6>Legal Team</h6>
                        <p className="text-white bg-purple-primary text-center rounded animate-pulse p-1">Em Breve</p>
                      </div>
                  </div>
              </article>
              <section className="flex items-center justify-center mt-10 border border-slate-400 rounded-xl">
                <div>
                     <div className=" flex flex-col items-center  h-28 justify-center bg-opacity-30 w-40">
                        <p className="text-2xl font-bold">{newClient}</p>
                        <p className="font-semibold">{t("details.client")}</p>
                     </div>

                     <div className="flex flex-col items-center  h-28 justify-center bg-opacity-30 w-40">
                        <p className="text-2xl font-bold">{newDesign}</p>
                        <p className="font-semibold text-center">{t("details.findings")}</p>
                     </div>
                </div>
                <div>
                     <div className="flex flex-col items-center h-28 justify-center  bg-opacity-30 w-40">
                        <p className="text-2xl font-bold">{newFindings}</p>
                        <p className="font-semibold text-center">{t("details.design")}</p>
                     </div>

                     <div className=" flex flex-col items-center  h-28 justify-center bg-opacity-30 w-40">
                        <p className="text-2xl font-bold">{newSmart}</p>
                        <p className="font-semibold text-center">{t("details.smart")}</p>
                     </div>
                </div>
              </section>
         </div>
         <div className="flex flex-col justify-center mt-10 gap-5 items-center w-full">
                  <a href="https://hub.nxchain.link" onClick={handleOpenContactArea} 
                  className="bg-purple-primary p-2 rounded-lg w-4/12 text-white font-semibold flex justify-center">
                   {t("details.button")}
                  </a>
          </div>
        </div>
      </div>
      <Certic/>
      <Benefits />
      <BannerWhitepaper />
      <Products />
      <Ressel />
      <Footer />
      <div onClick={() => {
        window.scrollTo({top: -10, behavior: 'smooth'})
        }} 
        className="fixed h-10 w-10 bg-purple-primary top-[90vh] right-5 rounded-3xl flex justify-center items-center">
        <KeyboardArrowUpIcon className="text-white"/>
      </div>
    </div>
  );
};
