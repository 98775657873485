import SMCONTRACT from '../../../assets/services/smcontract.png';
import IconAudit from '../../../assets/icon/smcontract.png';
import Shield from '../../../assets/icon/escudo.png';
import Process from '../../../assets/icon/nos.png';
import AuditGradient from '../../../assets/services/smc-gradient.png';
import Arrow from '../../../assets/icon/arrow.png';

export const servicesConfig = {
  // Outros serviços...
  uxDesign: {
    title: 'UX/UI DESIGN',
    paragraphPrimary:
      'Create Intuitive, User-Centered Designs to Elevate Your Digital Experience and Drive Results.',
    cardItems: [
      {
        title: 'User Research and Analysis',
        paragraph: `Understand your users deeply. Through research and data analysis, we discover pain points, needs, and expectations, creating a solid foundation for effective designs.`,
        icon: IconAudit,
      },
      {
        title: 'Prototyping and Testing',
        paragraph: `Transform ideas into interactive prototypes and test them with real users. Identify improvements early to ensure a seamless experience before launch.`,
        icon: Shield,
      },
      {
        title: 'Memorable User Experiences',
        paragraph: `Deliver designs that are not only functional but also delightful. Ensure users return by providing intuitive navigation, appealing visuals, and a cohesive brand presence.`,
        icon: Process,
      },
    ],
    steps: [
      { title: 'DISCOVERY PHASE', paragraph: 'Dive into the client’s business, understand user personas, and align on goals and expectations for the design.' },
      { title: 'WIREFRAMING', paragraph: 'Draft the initial structure of your platform, focusing on layout, hierarchy, and user flow.' },
      { title: 'PROTOTYPING', paragraph: 'Create interactive mockups to validate design ideas and gather user feedback for refinement.' },
      { title: 'VISUAL DESIGN', paragraph: 'Craft stunning visuals with attention to detail, ensuring alignment with your brand and user needs.' },
      { title: 'HANDOFF & ITERATION', paragraph: 'Provide design assets for development and remain involved in feedback loops for further improvements.' },
    ],
    mainImage: SMCONTRACT,
    gradientImage: AuditGradient,
    arrowIcon: Arrow,
  },
};
